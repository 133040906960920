import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./DashboardPage'))}/>
      <Route path={`${match.url}devis`} component={asyncComponent(() => import('./DevisPage'))}/>
      <Route path={`${match.url}intervention`} component={asyncComponent(() => import('./InterventionPage'))}/>
      <Route path={`${match.url}commande_fournisseur`} component={asyncComponent(() => import('./CommandeFournisseurPage'))}/>
      <Route path={`${match.url}commande_a_passer`} component={asyncComponent(() => import('./CommandeAPasserPage'))}/>
      <Route path={`${match.url}bon_reception`} component={asyncComponent(() => import('./BonReceptionPage'))}/>
      <Route path={`${match.url}facture`} component={asyncComponent(() => import('./FacturePage'))}/>
      <Route path={`${match.url}client`} component={asyncComponent(() => import('./ClientPage'))}/>
      <Route path={`${match.url}article`} component={asyncComponent(() => import('./ArticlePage'))}/>
      <Route path={`${match.url}avoir`} component={asyncComponent(() => import('./AvoirPage'))}/>
      <Route path={`${match.url}stock`} component={asyncComponent(() => import('./StockPage'))}/>
      <Route path={`${match.url}remise`} component={asyncComponent(() => import('./RemisePage'))}/>
      <Route path={`${match.url}flotte_client`} component={asyncComponent(() => import('./FlottePage'))}/>
      <Route path={`${match.url}sim_all`} component={asyncComponent(() => import('./SimPage'))}/>
      <Route path={`${match.url}type_cdr`} component={asyncComponent(() => import('./TypeCDRPage'))}/>
      <Route path={`${match.url}composant`} component={asyncComponent(() => import('./ComposantPage'))}/>
      <Route path={`${match.url}parametrages`} component={asyncComponent(() => import('./ParametragesPage'))}/>
      <Route path={`${match.url}bontreso`} component={asyncComponent(() => import('./BonTresoPage'))}/>
      <Route path={`${match.url}TestKevinPage`} component={asyncComponent(() => import('./TestKevinPage'))}/>
      <Route path={`${match.url}TestKevinPage`} component={asyncComponent(() => import('./TestThomasPage'))}/>
      <Route path={`${match.url}location`} component={asyncComponent(() => import('./LocationPage'))}/>
      <Route path={`${match.url}boutique_perso`} component={asyncComponent(() => import('./BoutiquePersoPage'))}/>
      <Route path={`${match.url}sav`} component={asyncComponent(() => import('./SAVPage'))}/>
      <Route path={`${match.url}charges`} component={asyncComponent(() => import('./ChargesFixesPage'))}/>
      <Route path={`${match.url}navette`} component={asyncComponent(() => import('./NavettePage'))}/>
      <Route path={`${match.url}reconditionnement`} component={asyncComponent(() => import('./Reconditionnement'))}/>
      <Route path={`${match.url}reprise`} component={asyncComponent(() => import('./ReprisePage'))}/>
      
      <Route path={`${match.url}repriseClient`} component={asyncComponent(() => import('./RepriseClientPage'))}/>
      <Route path={`${match.url}repriseClientFiche`} component={asyncComponent(() => import('./RepriseClientFiche'))}/>

      
      <Route path={`${match.url}stat_vente`} component={asyncComponent(() => import('./StatVentePage'))}/>
      <Route path={`${match.url}prelevement`} component={asyncComponent(() => import('./PrelevementPage'))}/>
      <Route path={`${match.url}achat`} component={asyncComponent(() => import('./AchatPage'))}/>
      <Route path={`${match.url}boncadeau`} component={asyncComponent(() => import('./BonCadeauPage'))}/>
      <Route path={`${match.url}demantelement`} component={asyncComponent(() => import('./DemantelementPage'))}/>
      <Route path={`${match.url}stat_reco`} component={asyncComponent(() => import('./StatRecoPage'))}/>
      <Route path={`${match.url}wholesale`} component={asyncComponent(() => import('./WholeSalePage'))}/>
      <Route path={`${match.url}tresorie`} component={asyncComponent(() => import('./TresoriePage'))}/>
      <Route path={`${match.url}inventaire`} component={asyncComponent(() => import('./InventairePage'))}/>
      <Route path={`${match.url}rapprochement_bancaire`} component={asyncComponent(() => import('./RapprochementBancairePage'))}/>
      <Route path={`${match.url}stat_achat`} component={asyncComponent(() => import('./StatAchatPage'))}/>
      <Route path={`${match.url}client_societe`} component={asyncComponent(() => import('./Client_SocietePage'))}/>
      <Route path={`${match.url}opportunite`} component={asyncComponent(() => import('./OpportunitePage'))}/>
      <Route path={`${match.url}fournisseur`} component={asyncComponent(() => import('./FournisseurPage'))}/>
      <Route path={`${match.url}planningIntervention`} component={asyncComponent(() => import('./PlanningInterventionPage'))}/>
      <Route path={`${match.url}export_facture`} component={asyncComponent(() => import('./ExportComptableFacturePage'))}/>
      <Route path={`${match.url}export_encaissement`} component={asyncComponent(() => import('./ExportComptableEncaissementPage'))}/>
      <Route path={`${match.url}export_encaissementV2`} component={asyncComponent(() => import('./ExportComptableEncaissementV2Page'))}/>
      <Route path={`${match.url}export_achat`} component={asyncComponent(() => import('./ExportComptableAchatPage'))}/>
      <Route path={`${match.url}export_avoir`} component={asyncComponent(() => import('./ExportComptableAvoirPage'))}/>
      <Route path={`${match.url}ligneMobileTelecom`} component={asyncComponent(() => import('./TELECOM_BACK/LigneMobilePage'))}/>


    </Switch>
  </div>
);

export default App;
